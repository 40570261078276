export default {
  name: 'SubModule',
  props: {
    moduleId: Number
  },
  data() {
    return {
      showSubModuleList: false,
      data: [],
      subModule: null,
      fields: [
        {
          key: 'module_id',
          class: 'd-none'
        },
        {
          key: 'module_name'
        },
        {
          key: 'sub_module_name'
        },
        {
          key: 'sub_module_desc'
        },
        {
          key: 'sub_module_id',
          class: 'd-none'
        }
      ]
    };
  },
  mounted() {
    this.showSubModuleList = true;
    this.getSubModuleLov();
  },
  methods: {
    getSubModuleLov() {
      const payload = {
        module_id: this.moduleId
      };
      this.$store
        .dispatch('docSetup/getSubModuleLov', payload)
        .then(response => {
          const result = response.data.data;
          this.data = result;
        });
    },
    rowSelected(rowData){
        this.$emit('selectedSubModule', rowData);
    }
  }
};
