import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'Documentsequence',
  watch: {
    currentPage: function() {
      this.getDocSequenceGridView();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getDocSequenceGridView();
    }
  },
  data() {
    return {
      unsubscribe: null,
      disableField: false,
      showAlert: false,
      isSuccess: false,
      loader: false,
      responseMsg: '',
      data: [],
      payload: {},
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      docSequence: null,
      addDocSequence: false,
      name: null,
      prefix: null,
      suffix: null,
      lastUsedNum: null,
      editMode: true,
      form: {
        sequence_name: null,
        prefix: null,
        suffix: null,
        last_used_number: null,
        document_sequence_id: 0
      },
      fields: [
        {
          key: 'document_sequence_id',
          class: 'd-none'
        },
        {
          key: 'sequence_name'
        },
        {
          key: 'prefix'
        },
        {
          key: 'suffix'
        },
        {
          key: 'last_used_number',
          label: 'Last Used No.'
        }
      ]
    };
  },
  mounted() {
    this.getDocSequenceGridView();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (this.$router.currentRoute.name === 'getDocSequencing') {
          if (actionName === 'add') {
            this.resetForm();
            this.showAlert = false;
            this.editMode = false;
            this.addDocSequence = true;
            this.disableField = false;
          }
          if (actionName === 'save') {
            if (!this.editMode) {
              this.addEditDocSequenceFun();
            }
          }
          if (actionName === 'update') {
            this.editMode = false;
          }
          if (actionName === 'download' && !this.addDocSequence) {
            this.loader = true;
            /**
             * @param(payload, 'action name', 'file name')
             */
            const downloadpayload = { ...this.payload };
            downloadpayload._limit = this.totalRows;
            this.downloadExcel.downloadData(
              downloadpayload,
              'docSetup/getDocSequenceList',
              'documentsequence',
              () => (this.loader = false)
            );
          }
          if (actionName === 'upload' && this.addDocSequence) {
            this.eventBus.$emit('commonUpload', {
              id: this.form.document_sequence_id
            });
          }
        }
      }
    });
  },
  methods: {
    resetModal() {},
    addEditDocSequenceFun() {
      const payload = {
        docSeqData: {
          document_sequence_id: this.form.document_sequence_id,
          last_used_number: this.form.last_used_number,
          prefix: this.form.prefix,
          sequence_name: this.form.sequence_name,
          suffix: this.form.suffix
        }
      };
      this.loader = true;
      this.$store
        .dispatch('docSetup/addDocSequence', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.editMode = true;
            this.disableField = true;
            this.getDocSequenceGridView();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
            this.disableField = true;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

    rowSelected(item) {
      if (this.$router.currentRoute.name === 'getDocSequencing') {
        this.loader = true;
        this.showAlert = false;
        this.editMode = true;
        this.disableField = true;
        this.addDocSequence = true;
        this.loader = false;
        this.form = {
          sequence_name: item.sequence_name,
          prefix: item.prefix,
          suffix: item.suffix,
          last_used_number: item.last_used_number,
          document_sequence_id: item.document_sequence_id
        };
        this.addDocSequence = true;
      }
      this.$emit('selectedSeqData', item);
    },
    getDocSequenceGridView() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        sequence_name: this.docSequence
      };
      this.loader = true;
      this.$store
        .dispatch('docSetup/getDocSequenceList', this.payload)
        .then(response => {
          this.loader = false;
          this.data = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
        })
        .catch(() => {
          this.loader = false;
        });  
    },
    clearDocSequence() {
      this.docSequence = null;
      this.getDocSequenceGridView();
    },
    resetForm() {
      this.disableField = true;
      this.form = {
        sequence_name: null,
        prefix: null,
        suffix: null,
        last_used_number: null,
        document_sequence_id: 0
      };
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
