import DatePicker from 'vue2-datepicker';
import openDocumentSequence from '../../documentsequence';
import { format } from 'date-fns';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'MapDocSequence',
  components: {
    openDocumentSequence,
    DatePicker
  },
  props: ['mapSequenceDetails', 'cat_value', 'cat_type'],
  data() {
    return {
      unsubscribe: null,
      editMode: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      showValuesetList: false,
      showMapDocSequenceForm: false,
      seqMpngTableIndex: 0,
      searchText: null,
      seq_cat_id: null,
      valuesetData: [],
      mapDocSequencedata: [
        {
          seq: null,
          sequence_id: null,
          end_date: null,
          start_date: format(new Date(), 'dd-MMM-yyyy'),
          category_meaning: null,
          sequence_mapping_id: 0,
          category_value: null,
          category_type: this.cat_type
        }
      ],
      mapDocSequencefields: [
        {
          key: 'seq',
          label: 'Doc Sequence'
        },
        {
          key: 'category_type',
          label: 'Category Type'
        },
        {
          key: 'category_meaning',
          label: 'Category Value'
        },
        {
          key: 'start_date_end_date',
          label: 'Start Date - End Date'
        }
      ],
      valuesetDataFields: [
        {
          key: 'value_meaning'
        },
        {
          key: 'value_code'
        }
      ],
      totalRows: null,
      currentPage: 1,
      perPage: null
    };
  },
  mounted() {
    if (this.cat_value) {
      this.getLOVBySetType(this.cat_value);
    }
    this.getMapSequenceDetails();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode) {
          this.addEditMapSequence();
        }
      }
    });
  },
  methods: {
    getMapSequenceDetails() {
      const doc_seq_cat_id = this.mapSequenceDetails.doc_seq_cat_id;
      this.loader = true;
      this.$store
        .dispatch('docSetup/getMapSequenceDetails', doc_seq_cat_id)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (
              response.data.data.Document_sequence_category &&
              response.data.data.Document_sequence_category.length > 0
            ) {
              this.mapDocSequencedata = response.data.data.Document_sequence_category.map(
                detail => {
                  detail.start_date_end_date = [
                    new Date(detail.start_date),
                    new Date(detail.end_date)
                  ];
                  return detail;
                }
              );
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditMapSequence() {
      const details = this.mapDocSequencedata.map(key => {
        return {
          category_type: key.category_type,
          category_value: key.category_value,
          start_date: commonHelper.formattedDate(key.start_date_end_date[0]),
          end_date: commonHelper.formattedDate(key.start_date_end_date[1]),
          sequence_id: key.sequence_id,
          sequence_mapping_id: key.sequence_mapping_id
        };
      });
      const payload = {
        seq_cat_id: this.mapSequenceDetails.doc_seq_cat_id,
        Document_sequence_category: details ? details : null
      };
      this.loader = true;
      this.$store
        .dispatch('docSetup/addEditMapSequence', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.mapDocSequencedata =
              response.data.data.Document_sequence_category;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    chooseValueSet(item) {
      this.mapDocSequencedata[this.seqMpngTableIndex].category_meaning =
        item.value_meaning;
      this.mapDocSequencedata[this.seqMpngTableIndex].category_value =
        item.value_code;
      this.showHideValuesetListModal(false);
    },
    showHideValuesetListModal(flag, index) {
      this.showValuesetList = flag;
      this.seqMpngTableIndex = index;
    },
    getLOVBySetType(valueSetName) {
      this.loader = true;
      const payload = {
        valueSetName: valueSetName,
        dependentVariable: {
          search_key: this.searchText,
          _page: this.currentPage - 1
        }
      };
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.valueSetFields = [];
            const result = response.data.data;
            this.valuesetData =
              result.data && result.data.length > 0 ? result.data : result.page;
            this.totalRows = result.total_elements;
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    getValueSetList() {
      this.getLOVBySetType(this.cat_value);
    },
    clearSearh() {
      this.searchText = null;
      this.getLOVBySetType(this.cat_value);
    },
    showHideDocumentsequence(flag, index) {
      this.showMapDocSequenceForm = flag;
      this.seqMpngTableIndex = index;
    },
    selectedSeqData(item) {
      this.mapDocSequencedata[this.seqMpngTableIndex].sequence_id =
        item.document_sequence_id;
      this.mapDocSequencedata[this.seqMpngTableIndex].seq = item.sequence_name;
      this.showHideDocumentsequence(false);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
