import DocNumDetails from './docNumDetails';
import SearchModule from '../responsibility/searchModule';
import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'DocumentNumbering',
    components: {
        DocNumDetails,
        SearchModule,
        ModelSelect
    },
    watch: {
      currentPage: function() {
        this.getDocNumberingGrid();
      },
      perPage: function() {
        this.currentPage = 1;
        this.getDocNumberingGrid();
      },
      module_id: function() {
        this.getSubModuleLov();
      }
    },
  data() {
    return {
      unsubscribe: null,
      totalRows: null,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
        showDocNumberModal: false,
        showModuleList: false,
        editMode: false,
        docNumberDetails: null,
        module_name: null,
        payload: {},
        module_id: null,
        subModuleOptions: [{
          value: null,
          text: null
        }],
      selectedSubModule: {
        value: null,
        text: null
      },
      data: [],
      fields: [
        {
          key: 'module_name',
          label: 'Module'
        },
        {
          key: 'sub_module_name',
          label: 'Sub Module'
        },
        {
          key: 'sub_module_desc',
          class: 'd-none'
        },
        {
          key: 'doc_name',
          label: 'Doc Class'
        },
        {
          key: 'doc_class_id',
          class: 'd-none'
        },
        {
          key: 'description'
        }
      ]
    };
  },
  mounted() {
    this.getDocNumberingGrid();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if(actionName === 'add'){
          this.showDocNumberModal = true;
          this.docNumberDetails = null;
        }        
        if (actionName === 'download' && !this.showDocNumberModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'docSetup/getDocNumberingGrid',
            'documentNumbering',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getSubModuleLov(){
      const payload = 
      {module_id: this.module_id }
      this.loader = true;
      this.$store.dispatch('docSetup/getSubModuleLov', payload)
      .then(response => {
        this.loader = false;
        const result = response.data.data;
        const sub_module_arr = result.map( result => {
          return {
            value: result.sub_module_id,
            text: result.sub_module_name
          };
        }
        );
        this.subModuleOptions = sub_module_arr;
      })
      .catch(() => {
        this.loader = false;
      });
    },
    getDocNumberingGrid() {
      this.payload = {
        _page: this.currentPage -1,
        _limit: this.perPage,
        module_id: this.module_id,
        sub_module_id: this.selectedSubModule.value
      };
      this.loader = true;
      this.$store
        .dispatch('docSetup/getDocNumberingGrid', this.payload)
        .then(response => {
          this.loader = false;
          this.data = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateDocNumberingList() {
      this.getDocNumberingGrid();
    },
    openModuleList() {
      this.showModuleList = true;
    },
    selectedModule(moduleData) {
      this.module_name = moduleData.module_name;
      this.module_id = moduleData.module_id;
      this.showModuleList = false;
    },
    rowSelected(rowData) {
      this.showDocNumberModal = true;
      this.docNumberDetails = rowData;
    },
    clearSearch(){
      this.selectedSubModule = {
        value: null,
        text: null
      },
      this.module_name = null
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
