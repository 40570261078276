import MapDocSequence from '../mapDocSequence';
import SubModule from '../subModule';
import State from '../../state';
import Module from '../../responsibility/searchModule';
import appStrings from '@/app/utility/string.utility';
import openValueSetMaster from '../../valueset';
export default {
  name: 'DocNumDetails',
  components: {
    State,
    MapDocSequence,
    SubModule,
    Module,
    openValueSetMaster
  },
  props: ['docNumberDetails'],
  watch: {
    cat_vset_id: function() {
      this.getDependentVsetForCat(this.cat_vset_id);
    },
    sub_cat_vset_id: function() {
      this.getDependentVsetForCat(this.sub_cat_vset_id);
    }
  },
  data() {
    return {
      docTableIndex: 0,
      unsubscribe: null,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      loader: false,
      showDocNumberModal: false,
      showSubModuleList: false,
      showModuleList: false,
      showValuesetMaster: false,
      showValueSetModal: false,
      showMapSequenceModal: false,
      showDepValuesetList: false,
      showStateModal: false,
      editMode: false,
      parent_value_set_id: null,
      module_name: null,
      module_id: 0,
      sub_module_id: 0,
      sub_module_name: null,
      doc_class_id: 0,
      doc_class: null,
      class_descrpition: null,
      cat_vset: null,
      cat_vset_id: null,
      sub_cat_vset: null,
      sub_cat_vset_id: null,
      sub_sub_cat_vset: null,
      sub_sub_cat_vset_id: null,
      vsetCode: null,
      cat_type: null,
      cat_value: null,
      mapSequenceDetails: null,
      depValuesetData: [],
      data: [
        {
          state: null,
          seq: null,
          le_id: null,
          legal_entity: null,
          doc_seq_id: 0,
          doc_seq_cat_id: 0,
          seq_cat_name: null,
          ou_name: null,
          ou_id: null,
          state_id: null
        }
      ],
      fields: [
        {
          key: 'add'
        },
        {
          key: 'remove'
        },
        {
          key: 'doc_seq_id',
          class: 'd-none'
        },
        {
          key: 'doc_seq_cat_id',
          class: 'd-none'
        },
        {
          key: 'seq_cat_name',
          label: 'Sequence Category'
        },
        {
          key: 'le_id',
          class: 'd-none'
        },
        {
          key: 'legal_entity'
        },
        {
          key: 'ou_id',
          class: 'd-none'
        },
        {
          key: 'ou_name',
          label: 'Organizational Unit'
        },
        {
          key: 'state_id',
          class: 'd-none'
        },
        {
          key: 'state'
        },
        {
          key: 'start_date',
          label: 'Mapping Start Date'
        },
        {
          key: 'end_date',
          label: 'Mapping End Date'
        },
        {
          key: 'seq',
          label: 'Map'
        }
      ],
      depValuesetDataFields: [
        {
          key: 'value_set_name'
        },
        {
          key: 'value_set_type_lookup'
        }
      ]
    };
  },
  mounted() {
    if (this.docNumberDetails) {
      this.getDocNumberingDetails(this.docNumberDetails.doc_class_id);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }
        if (
          actionName === 'save' &&
          this.editMode &&
          !this.showMapSequenceModal
        ) {
          this.addEditDocumentNumbering();
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.doc_class_id });
        }
      }
    });
  },
  methods: {
    getDocNumberingDetails(doc_class_id) {
      this.$store
        .dispatch('docSetup/getDocNumberingDetails', doc_class_id)
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            if (result.document_sequence_category.length > 0) {
              this.data = result.document_sequence_category;
            }
            this.module_id = result.module_id;
            this.module_name = result.module_name;
            this.sub_module_name = result.sub_module_name;
            this.sub_module_id = result.sub_module_id;
            this.doc_class = result.doc_class;
            this.doc_class_id = result.doc_class_id;
            this.class_descrpition = result.class_descrpition;
            this.cat_vset = result.cat_vset;
            this.category = result.category;
            this.sub_cat_vset = result.sub_cat_vset;
            this.sub_category = result.sub_category;
            this.sub_sub_cat_vset = result.sub_sub_cat_vset;
            this.sub_sub_category = result.sub_sub_category;
          }
        });
    },
    addEditDocumentNumbering() {
      const filterDetails = this.data.filter(
        docNumberDetail => docNumberDetail.seq_cat_name !== null
      );
      const details = filterDetails.map(data => {
        return {
          doc_seq_cat_id: data.doc_seq_cat_id,
          le_id: data.le_id,
          ou_id: data.ou_id,
          seq_cat_name: data.seq_cat_name,
          state_id: data.state_id
        };
      });
      const payload = {
        doc_class_id: this.doc_class_id,
        sub_module_id: this.sub_module_id,
        doc_name: this.doc_class,
        description: this.class_descrpition,
        cat_vset: this.cat_vset,
        sub_cat_vset: this.sub_cat_vset,
        sub_sub_cat_vset: this.sub_sub_cat_vset,
        document_mapping_details: details ? details : null
      };
      this.$store
        .dispatch('docSetup/addEditDocumentNumbering', payload)
        .then(response => {
          this.$emit('updateDocNumberingList');
          this.showAlert = true;
          if (response.status === 201) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.editMode = false;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.showAlert = true;
          this.loader = false;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
          this.error = err;
        });
    },
    getDependentVsetForCat(vset_id) {
      const parent_value_set_hdr_id = vset_id;
      this.loader = true;
      this.$store
        .dispatch(
          'shared/getDependentValueSetByParentValueset',
          parent_value_set_hdr_id
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.depValuesetData = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    chooseDepValueSet(item) {
      if (this.categoryType === 'SUB_CAT') {
        this.sub_cat_vset = item.value_set_name;
        this.sub_cat_vset_id = item.value_set_hdr_id;
      } else {
        this.sub_sub_cat_vset = item.value_set_name;
        this.sub_sub_cat_vset_id = item.value_set_hdr_id;
      }
      this.showHideDepValuesetModal(false);
    },
    addNewRow() {
      if (this.editMode) {
        this.data.push({
          doc_seq_id: 0,
          doc_seq_cat_id: 0,
          seq_cat_name: null,
          seq: null,
          le_id: null,
          legal_entity: null,
          ou_id: null,
          ou_name: null,
          state_id: null,
          state: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.data.splice(index, 1);
      }
    },
    enableInputs(index, commIndex) {
      this.detailIndex = index;
      this.responseIndex = commIndex;
    },
    openMapDocSequence(item) {
      if (this.editMode) {
        this.mapSequenceDetails = item;
        this.getCategoryValueAndType();
        this.showMapSequenceModal = true;
      }
    },
    getCategoryValueAndType() {
      if (this.sub_sub_cat_vset !== null && this.sub_sub_cat_vset !== '') {
        this.cat_value = this.sub_sub_cat_vset;
        this.cat_type = 'SUB_SUB_CAT';
      } else if (this.sub_cat_vset !== null && this.sub_cat_vset !== '') {
        this.cat_value = this.sub_cat_vset;
        this.cat_type = 'SUB_CAT';
      } else if (this.cat_vset !== null && this.cat_vset !== '') {
        this.cat_value = this.cat_vset;
        this.cat_type = 'CAT';
      }
    },
    openSubModuleList() {
      if (this.editMode) {
        this.showSubModuleList = true;
      }
    },
    selectedSubModule(subModuleObj) {
      this.sub_module_id = subModuleObj.sub_module_id;
      this.sub_module_name = subModuleObj.sub_module_name;
      this.showSubModuleList = false;
    },
    openModuleList() {
      this.showModuleList = true;
      this.sub_module_id = null;
      this.sub_module_name = null;
    },
    selectedModule(item) {
      this.module_id = item.module_id;
      this.module_name = item.module_name;
      this.showModuleList = false;
    },
    openStateComponent(index) {
      if (this.editMode) {
        this.docTableIndex = index;
        this.showStateModal = true;
      }
    },
    selectedState(stateData) {
      this.data[this.docTableIndex].state = stateData.state;
      this.data[this.docTableIndex].state_id = stateData.state_id;
      this.showStateModal = false;
    },
    showHideValuesetMaster(flag, categoryType) {
      this.showValuesetMaster = flag;
      this.categoryType = categoryType;
    },
    selectValueSetDetail(item) {
      if (this.categoryType === 'CAT') {
        this.cat_vset = item.value_set_name;
        this.cat_vset_id = item.value_set_hdr_id;
      }
      this.showHideValuesetMaster(false);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.data[this.docTableIndex].legal_entity = item.value_code;
        this.data[this.docTableIndex].le_id = item.value_set_dtl_id;
      } else {
        this.data[this.docTableIndex].ou_name = item.value_code;
        this.data[this.docTableIndex].ou_id = item.value_set_dtl_id;
      }
    },
    openValueSetModal(vsetCode, index) {
      if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.data[index].le_id;
      } else {
        this.parent_value_set_id = null;
      }
      this.vsetCode = vsetCode;
      this.docTableIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHideDepValuesetModal(flag, categoryType) {
      this.showDepValuesetList = flag;
      this.categoryType = categoryType;
    },
    resetForm() {
      this.sub_module_id = 0;
      this.sub_module_name = null;
      this.doc_class_id = 0;
      this.doc_class = null;
      this.class_descrpition = null;
      this.cat_vset = null;
      this.sub_cat_vset = null;
      this.cat_vset_id = null;
      this.cat_vset_dtl_id = null;
      this.sub_cat_vset_id = null;
      this.sub_sub_cat_vset = null;
      this.sub_sub_cat_vset_id = null;
      this.category = null;
      this.sub_category = null;
      this.sub_sub_category = null;
      this.data = [
        {
          state: null,
          seq: null,
          le_id: 0,
          legal_entity: null,
          doc_seq_id: 0,
          doc_seq_cat_id: 0,
          seq_cat_name: null,
          ou_name: null,
          ou_id: 0,
          state_id: 0
        }
      ];
    },
    resetModal() {},
    clearVsetValues(vsetCode) {
      if (vsetCode === 'CAT') {
        this.cat_vset = null;
        this.cat_vset_id = null;
      } else if (vsetCode === 'SUB_CAT') {
        this.sub_cat_vset = null;
        this.sub_cat_vset_id = null;
      } else if (vsetCode === 'SUB_SUB_CAT') {
        this.sub_sub_cat_vset = null;
        this.sub_sub_cat_vset_id = null;
      }
    },
    clearVsetValuesOnGrid(vsetCode, index) {
      if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.data[index].legal_entity = null;
        this.data[index].le_id = null;
      } else if (vsetCode === 'OU_LIST') {
        this.data[index].ou_name = null;
        this.data[index].ou_id = null;
      }
    }
  },
  beforeDestroy() {
    this.$store.dispatch('shared/setUnsetOrgType', null);
    this.unsubscribe();
  }
};
